import axios from "axios";

const USER_URL = "/api/users";

//login auth
export const Login = async (creds) => {
  const { Name, Password } = creds;

  if (!Name || !Password) return false;

  const response = await axios.post(USER_URL + "/login", creds, {
    headers: {
      "content-type": "application/json",
    },
    withCredentials: true 
  });
  if (response.status === 200) {
    return response.data;
  }
};

// check auth

export const checkAuth = async () => {
  const response = await axios.get(USER_URL + "/getuser");

  if (response.status === 200) {
    return response.data;
  }
};
