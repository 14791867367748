import helpers from "../helpers";
import { useEffect, useState } from "react";
import {  Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { addNewAvance, getCandidates,deleteAvance } from "../Http/Candidates";
import { checkAuth } from "../Http/users";
import { getAutos } from "../Http/autoEcoles";
import Header from "../components/reusable/Header";
import Nav from "../components/Nav";
import Spinner from "../components/reusable/Spinner/Spinner";
import { FiEdit } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsPlusCircleFill, BsFillEyeFill } from "react-icons/bs";
import { CategoryOptions } from "../constants";
import SmallScreenNav from "../components/reusable/SmallScreenNav";
import FlashNotification from "../components/reusable/FlashNotification"

export default function Index() {
  const [notif,setNotif]=useState({
    type:null,
    message:""
  })
  const [user, setUser] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [autos, setAutos] = useState([]);
  const navigate = useNavigate();
  const[showMobMenu,setShowMobMenu]=useState(false)
  const [search, setSearch] = useState("");
  const defaultAuto = localStorage.getItem("autoEcole") || "Akka";
  const [selectedAuto, setSelectedAuto] = useState(defaultAuto);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [displayedCandidates, setDisplayedCandidates] =
    useState(filteredCandidates);
  const [isLoading, setIsLoading] = useState(true);
  const [willAddAvance, setWillAddAvance] = useState(false);
  const [avanceToCandidate, setAvanceToCandidate] = useState(null);
  const [avance, setAvance] = useState({
    Montant: "",
    dateAvance: moment().format("DD-MM-YYYY"),
  });
  const [showTranchesList, setShowTranchesList] = useState(false);
  const [candidatToShowTranchesList, setCandidatToShowTranchesList] =
    useState(null);

  const [filter, setFilter] = useState("Tout");
  const [categoryFilter, setCategoryFilter] = useState("Tout");
  const filterOptions = ["Tout", "Ayant payé", "En attente"];
  useEffect(() => {
    getUser();
    loadCandidates();
    loadAutos();
    setFilteredCandidates(
      candidates?.filter((candidate) => candidate.autoEcole === selectedAuto)
    );
    filteredCandidates.length > 0 && filterByPaiement(filter);
    displaySearch(search);
    setIsLoading(false);
  }, [
    
    selectedAuto,
    search,
    filter,
    categoryFilter,
    candidates.length,
    filteredCandidates.length,
  ]);

  const bodyClass = willAddAvance
    ? " relative h-screen   border rounded-sm shadow-sm w-full  mx-auto  flex justify-center items-start"
    : " relative    border rounded-sm shadow-sm w-full  mx-auto  flex justify-center items-start";
  const candidatesTableTh =
    "text-xs md:text-sm border border-white tracking-wide py-1 md:py-2";

    const setNotification=()=>{
      return notif
    }
    const toggleMobileMenu=()=>{
      setShowMobMenu(!showMobMenu)
    }
    
  const changeSelectedAuto = (payload) => {
    localStorage.setItem("autoEcole", payload);
    setSelectedAuto(payload);
  };

  const displaySearch = (string) => {
    let searchResults = [];
    if (string !== "") {
      let foundInFnames = displayedCandidates.filter((candidate) =>
        candidate.Fname.toLowerCase().includes(search.toLowerCase())
      );
      let foundInLnames = displayedCandidates.filter((candidate) =>
        candidate.Lname.toLowerCase().includes(search.toLowerCase())
      );

      searchResults = [].concat(foundInFnames, foundInLnames);
      const uniqueResults = [...new Set(searchResults)];
      if (!uniqueResults.length > 0) {
        setIsLoading(false);
        setNotif({
          type:"error",
          message:"Aucun enregistrement ne correspond à votre recherche"
        });
        setTimeout(() => {
          setNotif({
            type:null,
            message:""
          })
          setDisplayedCandidates(filteredCandidates);
        }, 2000);
      }
      setDisplayedCandidates(uniqueResults);
    }
  };

  const paidCandidates =
    filteredCandidates.length > 0
      ? filteredCandidates.filter(
          (candidate) =>
            parseInt(candidate.Price) ===
            helpers.sumOfPropertyOfArrayOfObjects(candidate.Avances, "Montant")
        )
      : [];

  const filterByPaiement = (filter) => {
    switch (filter) {
      case "Ayant payé":
        if (categoryFilter !== "Tout") {
          return setDisplayedCandidates(
            paidCandidates.filter(
              (candidate) => candidate.Categorie === categoryFilter
            )
          );
        }
        setDisplayedCandidates(paidCandidates);
        break;
      case "En attente":
        if (categoryFilter !== "Tout") {
          return setDisplayedCandidates(
            filteredCandidates.filter(
              (candidate) =>
                parseInt(candidate.Price) !==
                  helpers.sumOfPropertyOfArrayOfObjects(
                    candidate.Avances,
                    "Montant"
                  ) && candidate.Categorie === categoryFilter
            )
          );
        }
        setDisplayedCandidates(
          filteredCandidates.filter(
            (candidate) =>
              parseInt(candidate.Price) !==
              helpers.sumOfPropertyOfArrayOfObjects(
                candidate.Avances,
                "Montant"
              )
          )
        );
        break;
      case "Tout":
        if (categoryFilter !== "Tout") {
          return setDisplayedCandidates(
            filteredCandidates.filter(
              (candidate) => candidate.Categorie === categoryFilter
            )
          );
        }
        setDisplayedCandidates(filteredCandidates);

        break;

      default:
        return;
    }
  };

  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);

      navigate("/?error=1");
    }
  };
  const loadCandidates = async () => {
    try {
      const response = await getCandidates();

      setCandidates(response.data);
    } catch (error) {
      const errorMesage = error.response.data.error || error.message;
      console.log(errorMesage);
    }
  };

  const loadAutos = async () => {
    try {
      const response = await getAutos();

      setAutos(response.data);
    } catch (error) {}
  };

  const onAddAvanceChange = (e) => {
    setNotif({
      type:null,
      message:""
    });
    setAvance({
      ...avance,
      [e.target.name]: e.target.value,
    });
  };

  const openModalAddTranche = (candidateId) => {
    setAvance({});
    setNotif({
      type:null,
      message:""
    });
    setNotif({
      type:null,
      message:""
    });
    setWillAddAvance(true);
    setAvanceToCandidate(candidateId);
  };
  const addAvance = async (e) => {
    e.preventDefault();
    if (avance.Montant === "" || !parseInt(avance.Montant))
      return setNotif({
        type:"error",
        message:"Merci de saisier le montant en chiffre"
      });
    const dateAvance = moment(avance.dateAvance);
    const tranche = {
      id: avanceToCandidate._id,
      newAvance: avance.Montant,
      dateAvance: dateAvance.isValid() ? dateAvance : moment().format(),
    };
    setIsLoading(true);
    try {
      const response = await addNewAvance(tranche);
      if (response.data) {
        setNotif({
          type:"success",
          message:response.data.success
        });
        displayedCandidates.find(candidate=>candidate._id === avanceToCandidate._id).Avances.push(response.data.newAvance)

        setIsLoading(false);
        setTimeout(() => {
         
          setNotif({
            type:null,
            message:""
          });
          setWillAddAvance(false);
        }, 1000);
      }
    } catch (error) {
      const errorMessage = error.response.data.error || error.message ;
      setNotif({
        type:"error",
        message:"Merci de saisier le montant en chiffre"
      });
      setIsLoading(false);
    }
  };

  const removeAvance =async(candidateId,avanceId)=>{
    if(!window.confirm("Voulez vous vraiment suuprimer cet enregistrement?")){
      return
    }
   setIsLoading(true)
    try {
      const response = await deleteAvance(candidateId,avanceId)
      if(response.data){
        displayedCandidates.find(candidate=>candidate._id === candidateId).Avances.filter(av=>av._id !== response.data.deletedAvance._id)
        setNotif({
          type:"success",
          message:response.data.success
        })
        setIsLoading(false);
        setTimeout(() => {
          closeTranchesList()

          setNotif({
            type:null,
            message:""
          });
        }, 1000);
      }
      
    } catch (error) {
       console.log(error)
    }
  }

  const openTranchesListModal = (candidat) => {
    setNotif({
      type:null,
      message:""
    });
    setShowTranchesList(true);
    setCandidatToShowTranchesList(candidat);
  };

  const closeTranchesList = () => {
    setShowTranchesList(false);
    setCandidatToShowTranchesList(null);
  };

 return  (
    <section className={bodyClass}>
      {willAddAvance && (
        <div className="absolute h-screen   w-full  bg-blue-500/[.3] flex justify-center items-center  ">
          <div className="w-4/5 lg:w-2/5 p-4 pb-8 rounded bg-white flex flex-col justify-start items-center">
            <h1 className="text-cyan-600 w-11/12 text-center tracking-wide text-lg font-bold py-2 px-4 border border-cyan-600">
              Ajouter une nouvelle tranche
            </h1>
            <div className="w-full my-4 h-10 flex justify-center items-center ">
              <FlashNotification setNotification={setNotification}/>
              
            </div>
            {isLoading && <Spinner/>}
            <form
              className="p-2 w-11/12  flex flex-col justify-start items-center"
              onSubmit={addAvance}
            >
              <input
                type="number"
                name="Montant"
                value={avance.Montant}
                className="outline-none p-2 w-full m-2  bg-gray-200 border rounded "
                placeholder="Montant"
                onChange={onAddAvanceChange}
              />
              <input
                className="outline-none p-2 w-full m-2  bg-gray-200 border rounded "
                type="date"
                name="dateAvance"
                value={avance.dateAvance}
                onChange={onAddAvanceChange}
              />
              <div className="w-full flex justify-evenly items-center">
                <input
                  type="submit"
                  value="Enregistrer"
                  className="p-2 w-1/3 bg-cyan-600 text-white cursor-pointer hover:bg-cyan-500 delay-100"
                />
                <button
                  onClick={() => setWillAddAvance(false)}
                  className="p-2 w-1/3 bg-gray-600 text-white cursor-pointer hover:bg-gray-500 delay-100"
                >
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showTranchesList && (
        <div className="absolute h-screen   w-full  bg-blue-500/[.3] flex justify-center items-center  ">
          <div className="w-4/5 relative lg:w-2/5 p-4 pb-8 rounded bg-white flex flex-col justify-start items-center">
           
          <FlashNotification setNotification={setNotification}/>
          {isLoading && <Spinner/>}

          <div
              onClick={() => closeTranchesList()}
              className="absolute right-3 top-2   text-red-600 cursor-pointer"
            >
              <span className="px-1 border border-red-600">X</span>
            </div>
            <div className="w-full lg:w-4/5 my-4 h-10 flex justify-center items-center border border-cyan-600 ">
              <h1 className="text-cyan-600 tracking-wide text-lg font-bold">
                Cumul des tranches
              </h1>
            </div>

            {candidatToShowTranchesList && (
              <table className="   w-full lg:w-4/5 rounded text-center tracking-wide">
                <thead className="bg-cyan-600 text-white ">
                <tr>
                <th className="p-1 border">Montant</th>
                <th className="p-1 border">Date</th>
                </tr>
                 
                </thead>
                <tbody>
                  {candidatToShowTranchesList.Avances.map((av) => {
                    return (
                      <tr key={av._id} className="border   text-gray-700">
                        <td className="border">{av.Montant} dh</td>
                        <td className="border flex justify-center items-center">
                          {moment(av.dateAvance).format("DD-MM-yyyy")}{" "}
                          <TiDeleteOutline onClick={()=>removeAvance(candidatToShowTranchesList._id,av._id)} className="text-red-500 mx-4 hover:text-red-400 text-2xl cursor-pointer" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <div className="w-full  lg:w-4/5 mt-6 flex justify-end items-center p-2">
              <h2 className="text-lg  text-gray-500 font-bold tracking-wide">
                Total:
              </h2>
              <span className="text-cyan-800 text-lg font-bold px-4 tracking-wide ">
                {helpers.sumOfPropertyOfArrayOfObjects(
                  candidatToShowTranchesList.Avances,
                  "Montant"
                )}{" "}
                dh
              </span>
            </div>
          </div>
        </div>
      )}
       <Nav user={user} /><SmallScreenNav user={user} showMobMenu={showMobMenu} toggleMobMenu={toggleMobileMenu}/>
      <div className="mx-2 w-full  lg:w-4/5 flex flex-col justify-start items-center">
        <Header
          autos={autos}
          selectedAuto={selectedAuto}
          changeSelectedAuto={changeSelectedAuto}
          setFilter={setFilter}
          toggleMobileMenu={toggleMobileMenu}
        />

        <div className=" border bg-white rounded-sm my-2 p-2  w-full flex flex-col md:flex-row justify-evenly items-center">
          <div className="mx-2 w-full md:w-1/4  flex justify-start items-center text-sm my-4">
            <span className="text-gray-700">Total Candidats :</span>
            <span className="text-white mx-2 px-3 text-lg bg-emerald-500  rounded">
              {filteredCandidates.length}
            </span>
          </div>
          <div className="mx-2 w-full md:w-1/4  flex justify-start items-center text-sm my-4">
            <span className="text-gray-700"> Ayant payé :</span>
            <span className="text-white text-lg  bg-emerald-500 mx-2 px-3  rounded">
              {paidCandidates.length}
            </span>
          </div>
          <div className="mx-2 w-full md:w-1/4  flex justify-start items-center text-sm my-4">
            <span className="text-gray-700">Filtrer: </span>
            <select
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              className="bg-white py-1 mx-2 px-2 outline-none  border-2 border-gray-300 text-slate-600"
            >
              {filterOptions.length > 0 &&
                filterOptions.map((filterOption, index) => {
                  return <option key={index}>{filterOption}</option>;
                })}
            </select>
            <select
              onChange={(e) => setCategoryFilter(e.target.value)}
              value={categoryFilter}
              className="bg-white py-1 mx-2 px-2 outline-none  border-2 border-gray-300 text-slate-600"
            >
              <option>Tout</option>
              {CategoryOptions.length > 0 &&
                CategoryOptions.map((CategoryOption, index) => {
                  return <option key={index}>{CategoryOption.value}</option>;
                })}
            </select>
          </div>
          <div className="mx-2 w-full md:w-1/4  flex justify-start items-center text-sm my-4">
            <span className="text-gray-700">Rechercher:</span>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              className="px-2 w-3/5 mx-2 py-1 border  outline-none border-gray-400 rounded text-gray-700 focus:border-cyan-500"
            />
          </div>
        </div>

        <div className="my-4 w-full flex justify-start items-center text-sm">
          <Link
            to="/candidats/nouveau"
            className="p-2 flex justify-evenly items-center bg-cyan-600 rounded text-white"
          >
            <span>Nouveau Candidat</span>
            <AiOutlineUserAdd className="mx-2 text-lg" />
          </Link>
        </div>
        <table className=" w-full p-2 text-xs md:text-sm border rounded bg-white shadow-md ">
          <thead className="text-white border bg-cyan-600 rounded ">
            <tr className="text-center">
              <th className={candidatesTableTh}>Nom Du candidat</th>
              <th className={candidatesTableTh}>Catégorie</th>
              <th className={candidatesTableTh}>Prix</th>
              <th className={candidatesTableTh}>Tranches</th>
              <th className={candidatesTableTh}>Date entrée</th>
              <th className={candidatesTableTh}>Opérations</th>
            </tr>
          </thead>
          <tbody>
            {displayedCandidates &&
              displayedCandidates.map((candidat, index) => {
                return (
                  <tr
                    className={
                      parseInt(candidat.Price) ===
                      helpers.sumOfPropertyOfArrayOfObjects(
                        candidat.Avances,
                        "Montant"
                      )
                        ? " fade text-xs md:text-sm text-emerald-600 font-bold  border"
                        : "fade text-gray-700 text-xs md:text-sm duration-1000  ease-in-out border"
                    }
                    key={candidat._id}
                  ><td
                      onClick={() => {
                        navigate("/candidats/edit/" + candidat._id);
                      }}
                      className="p-2 cursor-pointer hover:text-gray-600 hover:font-bold"
                    >
                      {candidat.Fname + " " + candidat.Lname + " "}
                      {candidat.issafen === true && (
                        <span className="text-gray-400">(issafen)</span>
                      )}
                    </td>
                    <td className="text-center">{candidat.Categorie}</td>
                    <td className="text-center">{candidat.Price}</td>
                    <td className="flex flex-col space-y-1 p-1 justify-evenly gap-4 md:flex-row md:space-y-0 items-center">
                      <span className="w-2/3 text-center">
                        {helpers.sumOfPropertyOfArrayOfObjects(
                          candidat.Avances,
                          "Montant"
                        )}
                      </span>
                      <span onClick={() => openModalAddTranche(candidat)}>
                        <BsPlusCircleFill className="text-blue-400 cursor-pointer hover:text-blue-500" />
                      </span>
                      <span onClick={() => openTranchesListModal(candidat)}>
                        <BsFillEyeFill className="text-gray-500 cursor-pointer hover:text-gray-600" />
                      </span>
                    </td>
                    <td className="text-center">
                      {moment(candidat.dateEntree).format("DD-MM-YYYY")}
                    </td>
                    <td className="flex flex-col space-y-2 p-1 md:p-2 md:flex-row md:space-y-0  justify-evenly items-center">
                      <Link
                        to={`/candidats/edit/${candidat._id}`}
                        className="cursor-pointer"
                      >
                        <FiEdit className="text-lg text-blue-500" />
                      </Link>
                      <Link
                        to={"/candidats/delete/" + candidat._id}
                        className="cursor-pointer"
                      >
                        <TiDeleteOutline className="text-2xl text-red-500" />
                      </Link>
                    </td>
                  </tr>
                );
              })}{" "}
          </tbody>
        </table>

        {!displayedCandidates.length > 0 &&
          displayedCandidates.length !== 0 && (
            <div className=" p-4 flex justify-center items center">
              <Spinner />
            </div>
          )}
        {displayedCandidates.length === 0 && <div className=" p-4 flex justify-center items center">
        <Spinner />
      </div>}
      </div>
    </section>
  );
}
