import { useEffect, useState } from "react";
import Nav from "../components/Nav";
import Header from "../components/reusable/Header";
import { checkAuth } from "../Http/users";
import { getAutos } from "../Http/autoEcoles";
import { useNavigate } from "react-router-dom";
import { getPersonnel } from "../Http/Personnel";
import PersonnelList from "../components/personnel/PersonnelList";
import AddPersonnel from "../components/personnel/AddPersonnel";
import SmallScreenNav from "../components/reusable/SmallScreenNav";



const Personnel = () => {
  const navigate = useNavigate();
  const[personnel,setPersonnel]=useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [notif,setNotif]=useState({
    type:null,
    message:""
  })
  const [user, setUser] = useState(null);
  const [autos, setAutos] = useState([]);
  const defaultAuto = localStorage.getItem("autoEcole") || "Akka";
  const [selectedAuto, setSelectedAuto] = useState(defaultAuto);
  const [reload,setReload]=useState(false)
  const[showMobMenu,setShowMobMenu]=useState(false)


  useEffect(() => {
    getUser();
    loadAutos();
    laodPersonnel()
    console.log(personnel)
  }, [selectedAuto,reload]);
  const changeSelectedAuto = (payload) => {
    localStorage.setItem("autoEcole", payload);
    setSelectedAuto(payload);
  };

  const setNotification = ()=>{
    return notif
  }
  const loadAutos = async () => {
    try {
      const response = await getAutos();

      setAutos(response.data);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      setNotif({
        type:"error",
        message:errorMessage
      });
      setIsLoading(false);
    }
  };

  const laodPersonnel =async ()=>{
    try {

      const response = await getPersonnel()

      if(response.data){
        setPersonnel(response.data.data)
        setIsLoading(false)
      }
      
    } catch (error) {
      const errorMessage = error.response.data.error || error.message
      setNotif({
          type:"error",
          message: errorMessage
      })
      setIsLoading(false)
    }
  }
  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);
      setIsLoading(false);
      navigate("/?error=1");
    }
  };
  const toggleMobileMenu=()=>{
    setShowMobMenu(!showMobMenu)
  }

  return <section className=" bg-inherit border rounded-sm shadow-sm w-full  mx-auto  flex flex-col items-center lg:flex-row justify-center lg:items-start">
  <Nav user={user} /><SmallScreenNav user={user} showMobMenu={showMobMenu} toggleMobMenu={toggleMobileMenu}/>
  <div className=" w-full lg:w-4/5 flex flex-col justify-center">
    <Header
      autos={autos}
      selectedAuto={selectedAuto}
      changeSelectedAuto={changeSelectedAuto}
      toggleMobileMenu={toggleMobileMenu}
    />

    <div className="w-full flex flex-col justify-start items-center   md:flex-row md:justify-between md:items-start">
   
    <AddPersonnel
         
            autos={autos}
            selectedAuto={selectedAuto}
            changeSelectedAuto={changeSelectedAuto}
            reload={reload}
            setReload={setReload}
          />
   
    <PersonnelList personnel={personnel} isLoading={isLoading} selectedAuto={selectedAuto} setNotification={setNotification}/>




    </div>
    </div>
    </section>
};

export default Personnel;
