import {useState, useEffect} from "react";
import Nav from "../components/Nav"
import { checkAuth } from "../Http/users";
import { useNavigate } from "react-router-dom";
import Header from "../components/reusable/Header";
import { getAutos } from "../Http/autoEcoles";
import { addVehicule } from "../Http/Vehicules";
import AddVehicule from "../components/vehicules/AddVehicule";
import VehiculesList from "../components/vehicules/VehciulesList";
import SmallScreenNav from "../components/reusable/SmallScreenNav";


const Vehicules = () => {
  const [vehicules,setVehicules]=useState([])
  const navigate = useNavigate()
  const [isLoading,setIsLoading]=useState(true)
  const [user, setUser] = useState(null);
  const [autos,setAutos] = useState([])
  const defaultAuto = localStorage.getItem('autoEcole') || "Akka"
  const [selectedAuto, setSelectedAuto] = useState(defaultAuto); 
  const[showMobMenu,setShowMobMenu]=useState(false)



  useEffect(()=>{
getUser()
loadAutos()

  },[selectedAuto])
  const changeSelectedAuto = (payload) => {
    localStorage.setItem("autoEcole", payload);
    setSelectedAuto(payload);
  };
  const toggleMobileMenu=()=>{
    setShowMobMenu(!showMobMenu)
  }

  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
      setIsLoading(false)
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);
    setIsLoading(false)
      navigate("/?error=1");
    }
  };
  const addNewVehicule=async (vehicule)=>{
    try {

      const response = await addVehicule(vehicule)
      if(response.data){
        setVehicules(prevstate=>{

          return [...prevstate,vehicule]
        })
      }
      
    } catch (error) {
      
    }
  }
  const loadAutos = async () => {
    try {
      const response = await getAutos();

      setAutos(response.data);
      setIsLoading(false)
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      setIsLoading(false)
    }
    const toggleMobileMenu=()=>{
      setShowMobMenu(!showMobMenu)
    }
  };
  return <section className=" bg-inherit border rounded-sm shadow-sm w-full  mx-auto  flex flex-col items-center lg:flex-row justify-center lg:items-start">
 
  <Nav user={user} /><SmallScreenNav user={user} showMobMenu={showMobMenu} toggleMobMenu={toggleMobileMenu}/>
  <div className="mx-2 w-full lg:w-4/5 flex flex-col justify-center">
    <Header autos={autos} selectedAuto={selectedAuto} changeSelectedAuto={changeSelectedAuto}/>
   
   <div className="w-full flex flex-col justify-start items-center md:flex-row md:justify-center md:items-start">
  
    <AddVehicule autos={autos} changeSelectedAuto={changeSelectedAuto} selectedAuto={selectedAuto}/>
    <VehiculesList selectedAuto={selectedAuto}/>
     
      </div>
     
    </div>
  </section>;
};

export default Vehicules;
