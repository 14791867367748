import { Login } from "../Http/users";
import { useEffect, useState } from "react";
import Spinner from "../components/reusable/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export default function Homepage() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    Name: "",
    Password: " ",
  });

  const errorParam = searchParams.get("error");

  useEffect(() => {
    if (errorParam === "1")
      setError("Session expirée. Merci de vous reconnecter");
  }, [errorParam]);

  const onChange = (e) => {
    setIsLoading(false);
    setError("");
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    if (!userData.Name || !userData.Password) {
      setIsLoading(false);
      return setError("Merci d'entrer votre Identifiant et votre mot de pass");
    }

    try {
      await Login(userData);

      navigate("/candidats");

      setIsLoading(false);
    } catch (err) {
      console.log(err);

      setError(err.response?.data.error || err.message);

      setIsLoading(false);
    }
  };

  return (
    <section className="bg-cyan-500 h-screen w-full flex justify-center items-center">
      <div className="p-2 bg-white w-4/5  lg:w-2/5 ">
        <div className="w-full flex justify-center items-center h-10 my-2">
          {isLoading && <Spinner />}{" "}
          {error && <span className="text-red-600 ">{error}</span>}
        </div>
        <form className="" onSubmit={onSubmit}>
          <div className="m-2 flex flex-col p-2 border border-gray-200 text-gray-800">
            <label className="text-gray-600 my-4">Votre Identifiant:</label>
            <input
              type="text"
              value={userData.Name}
              name="Name"
              onChange={onChange}
              className="outline-none border-b px-2 border-gray-300 focus:border-cyan-300"
            />
          </div>
          <div className="m-2 flex flex-col p-2 border border-gray-200 text-gray-800">
            <label className="text-gray-600 my-4">Votre Mot De Pass:</label>

            <input
              type="password"
              name="Password"
              value={userData.Password}
              onChange={onChange}
              className="outline-none  px-2 border-b border-gray-300 focus:border-cyan-300"
            />
          </div>
          <div className="m-2 p-2 border border-gray-200 text-gray-800">
            <input
              type="submit"
              value="se connecter"
              className="outline-none cursor-pointer bg-cyan-500 w-full p-2 hover:bg-cyan-600 text-white text-xl tracking-wide"
            />
          </div>
        </form>
      </div>
    </section>
  );
}
