import axios from "axios";

const CANDIDATES_URL = "/api/candidates";

export const getCandidates = async () => {
  const response = await axios.get(CANDIDATES_URL,{withCredentials:true});

  if (response.status === 200) {
    return response.data;
  }
};

export const addCandidat = async (candidat)=>{

  const response = await axios.post(CANDIDATES_URL+"/create",candidat,{
    headers:{
      "content-type":"application/json"
    },
    withCredentials: true 
 })

 if(response.status ===201){
  return response
 }
}


export const getCandidateById =async (id)=>{

  const response = await axios.get(CANDIDATES_URL+'/single/'+id,{ withCredentials: true })
  if(response.status === 200){
    return response
  }
}

export const updateCandidat = async (candidat)=>{

  const response = await axios.post(CANDIDATES_URL+"/update/"+candidat._id,candidat,{
    headers:{
      "content-type":"application/json"
    },
    withCredentials: true 
  })

  if(response.status === 200) return response
}

export const deleteCandidat = async (id)=>{

  const response = await axios.delete(CANDIDATES_URL+'/delete/'+id,{ withCredentials: true })
  if(response.status===200) return response
}

export const addNewAvance = async (avance)=>{
  const response = await axios.post(CANDIDATES_URL+'/avances/create',avance,{
    headers:{
      "content-type":"application/json"
    },
    withCredentials: true 
  })

  if(response.status ===201){
    return response
  }
}


export const deleteAvance = async (candidateId,avanceId)=>{

  const response = await axios.delete(CANDIDATES_URL+'/avance/delete/'+candidateId+'/'+avanceId)
  if(response.status === 200){
    return response
  }
}
