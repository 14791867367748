import { BrowserRouter, Route, Routes } from "react-router-dom";
import Candidates from "./pages/Candidates";
import Charges from "./pages/Charges";
import Dashboard from "./pages/Dashboard";
import Echeances from "./pages/Echeances";
import Login from "./pages/Login";
import Personnel from "./pages/Personnel";
import Vehicules from "./pages/Vehicules";
import AddCandidat from "./components/candidates/AddCandidat";
import EditCandidat from "./components/candidates/EditCandidat";
import DeleteCandidat from "./components/candidates/DeleteCandidat";

function App() {
  return (
    <>
      <BrowserRouter>
        <main className="w-full  flex flex-col justify-start items-start bg-[#fffefe] ">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/candidats" element={<Candidates />}/>
              <Route path="/candidats/nouveau" element={<AddCandidat/>}/>
              <Route path="/candidats/edit/:id" element={<EditCandidat/>}/>
            
              <Route path="/candidats/delete/:id" element={<DeleteCandidat/>}/>
            <Route path="/charges" element={<Charges />} />
            <Route path="/vehicules" element={<Vehicules />} />
            <Route path="/echeances" element={<Echeances />} />
            <Route path="/personnel" element={<Personnel />} />
          </Routes>
        </main>
      </BrowserRouter>
    </>
  );
}

export default App;
