import axios from "axios"

const VEHICULES_URL = '/api/vehicules'



export const addVehicule =async (vehicule)=>{

    const response = await axios.post(VEHICULES_URL+'/create',vehicule,{

        headers:{
            "content-type":"application/json"
        },
        withCredentials: true 
    })

    if(response.status === 201){

        return response
    }
}


export const getVehicules =async ()=>{

    const response = await axios.get(VEHICULES_URL,{ withCredentials: true })
    
    if(response.status === 200){
        return response
    }
}