import axios from "axios";

const ECHEANCE_URL = "/api/echeances";


export const getEcheances = async ()=>{
    const response = await axios.get(ECHEANCE_URL,{ withCredentials: true })
    if(response.status === 200){
        
        return response
    }
}


export const createEcheance=async (echeance)=>{

    const response = await axios.post(ECHEANCE_URL+'/create', echeance,{

        headers:{
            "content-type":"application/json"
        },
        withCredentials: true 
    })
    if(response.status === 201){
        return response
    }
}




