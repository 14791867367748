import moment from "moment";
import { useState } from "react";
import { AiOutlineStop } from "react-icons/ai";
import { createEcheance } from "../../Http/Echeance";
import FlashNotification from "../reusable/FlashNotification";

const EcheancesList = ({ echeances, selectedAuto, setReload, reload }) => {
  const perAutoEcheances = echeances?.filter(
    (each) => each.autoEcole === selectedAuto
  );
  const [notif, setNotif] = useState({
    type: null,
    message: "",
  });

  const setNotification = () => {
    return notif;
  };

  const timeFormat = (input) => {
    let year = moment().format("YYYY");
    let month =parseInt(input) > parseInt(moment().date()) ? parseInt(moment().format("MM")): parseInt(moment().format("MM")) + 1;

    if (month > 12) {
      month = `01`;
      year +=  1;
    }
    if (parseInt(input) < 10) {
      input = `0${input}`;
    }
    if (parseInt(month) < 10) {
      month = `0${month}`;
    }
    
   

    return ` ${input}-${month}-${year}`;
  };

  const cancelTask = async (task) => {
    if (window.confirm("Voules vous vraiment supprimer cet enregistrement")) {
      try {
        task.stopTask = "1";
        const response = await createEcheance(task);
        setReload(!reload);

        console.log(response.data);

        setNotif({
          type: "success",
          message: "Echeance supprimée avec succès",
        });

        return;
      } catch (error) {
        console.log(error);
        return;
      }
    }
    return;
  };

  return (
    <div className="w-full lg:w-3/5 order-2 md:order-1">
      <FlashNotification setNotification={setNotification} />
      <table className="w-full  mx-auto text-center">
        <thead className="bg-cyan-600 tracking-wider  text-sm  text-white">
          <tr>
            <th className="p-2 border">Libelle</th>
            <th className="p-2 border">Type</th>
            <th className="p-2 border">Montant</th>
            <th className="p-2 border">Prochaine Echeance</th>
          </tr>
        </thead>
        <tbody>
          {perAutoEcheances.map((echeance) => {
            return (
              <tr className="border fade text-gray-700" key={echeance._id}>
                <td>{echeance.Libelle}</td>
                <td>{echeance.typeEcheance}</td>
                <td>{echeance.Montant}</td>
                <td className="flex justify-center items-center">
                  {timeFormat(echeance.jourEcheance)}
                  <span className="mx-4">
                    <AiOutlineStop
                      onClick={() => cancelTask(echeance)}
                      className="text-red-500 cursor-pointer"
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EcheancesList;
