import Spinner from "../reusable/Spinner/Spinner";
import moment from "moment";
import { useEffect, useState } from "react";
import { getVehicules } from "../../Http/Vehicules";

const VehiculesList = ({ selectedAuto }) => {
  const [vehicules, setVehicules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentMonthCharges, setCurrentMonthCharges] = useState([]);
  let years = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
    2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040,
  ];
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  useEffect(() => {
    loadVehicules();
    
  }, [selectedAuto, vehicules.length]);

  const vehciulesPetAuto = vehicules?.filter(vh=>vh.autoEcole === selectedAuto)
  const loadVehicules = async () => {
    try {
      const response = await getVehicules();
      if (response.data) {
        setVehicules(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full lg:w-3/5 order-2 md:order-1">
      {/* {isLoading ?  (
        <div className="w-full flex justify-center items-start">
          <Spinner />
        </div>
      ) :( */}
      <>
        <div className="fade w-11/12 mx-auto flex justify-center gap-2 items-center bg-white border shadow-sm rounded">
          <h2 className="mx-2 text-gray-700  font-medium ">
            filter par date :
          </h2>
          <form>
            <select
              className="p-2 bg-gray-200 outline-none m-2"
              // value={selectedMonth}
              // onChange={(e)=>onMonthChange(e)}
            >
              {months.map((month) => {
                return <option key={month}>{month}</option>;
              })}
            </select>
            <select
              // onChange={(e) => setSelectedYear(parseInt(e.target.value) )}
              className=" p-2 bg-gray-200 outline-none m-2"
              // value={selectedYear}
            >
              {years.map((year) => {
                return <option key={year}>{year}</option>;
              })}
            </select>
            <input
              type="submit"
              value="Filtrer"
              className=" p-2 px-4 rounded tracking-wider bg-cyan-600 text-white cursor-pointer hover:bg-cyan-500 outline-none m-2"
            />
          </form>
        </div>
        <div className="p-2 w-11/12 bg-white my-2 border border-gray-200 rouneded">
          <table className="w-full  mx-auto text-center ">
            <thead className="bg-cyan-600 tracking-wider  text-sm  text-white">
              <th className="p-2 border">N° d'immatriculation</th>
              <th className="p-2 border">Date fin assurance</th>
              <th className="p-2 border">Date fin visite technique</th>
            </thead>
            <tbody>
              {vehciulesPetAuto.length>0 &&
                vehciulesPetAuto.map((vh) => {
                  return (
                    <tr className="border fade text-gray-700" key={vh._id}>
                      <td>{vh.Immat}</td>
                      <td>{moment(vh.dateAssur).format("DD-MM-YYYY")}</td>
                      <td>{moment(vh.dateVisite).format("DD-MM-YYYY")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className=" w-full lg:w-3/4 p-2 my-2 flex justify-start items-center">
            <h2 className="text-gray-600 font-bold text-lg p-2">
              Total des charges du mois:
            </h2>
            <span className="bg-red-600 font-bold text-xl px-3 fade rounded-md text-white tracking-wide"></span>
          </div>
        </div>
      </>
    </div>
  );
};

export default VehiculesList;
