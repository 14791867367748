const Header = ({
  autos,
  selectedAuto,
  changeSelectedAuto,
  setFilter = null,
  toggleMobileMenu
}) => {
  const onChange = (e) => {
    changeSelectedAuto(e.target.value);
    setFilter !== null && setFilter("Tout");
  };
  return (
    <header className="<bg-white border shadow-sm rounded-sm flex justify-between items-center my-2 w-full p-4">
      <div className=" flex-grow flex justify-start items-center">
        <span className="text-gray-700 text-sm md:text-lg">Selectionner Auto Ecole: </span>
        <select
          onChange={onChange}
          value={selectedAuto}
          className="bg-white py-1 px-3 outline-none mx-4 border-2 border-gray-300 text-slate-600"
        >
          {autos.length > 0 ?
            autos.map((auto) => {
              return <option key={auto.Name}>{auto.Name}</option>;
            }):<option>Auto</option>}
        </select>
      </div>
      <div onClick={toggleMobileMenu} className="w-2/12 py-3 cursor-pointer border border-white hover:border-gray-100 sm:w-2/12 md:hidden flex flex-col justify-arround p-1 space-y-2 items-center ">
        <div  className="w-1/2 h-1 rounded  bg-gray-400   "></div>
        <div  className="w-1/2 h-1 rounded  bg-gray-400  "></div>
        <div  className="w-1/2 h-1 rounded  bg-gray-400  "></div>
       
      </div>
    </header>
  );
};

export default Header;
