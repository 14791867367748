import FlashNotification from "../reusable/FlashNotification";
import Spinner from "../reusable/Spinner/Spinner";
const PersonnelList = ({ personnel, isLoading,setNotification }) => {
  return (
    <section className="w-full lg:w-3/5 order-2 md:order-1">
      {isLoading ? (
        <div className="w-full flex justify-center items-start">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="fade w-full p-2 mx-auto flex flex-col justify-start  gap-2 items-center bg-white border shadow-sm rounded">
          <FlashNotification setNotification={setNotification}/>
          <table className="w-full my-2  mx-auto text-center">
          <thead className="bg-cyan-600 tracking-wider  text-sm  text-white">
          <tr>
                  <th className="p-2 border">Nom Complet</th>
                  <th className="p-2 border">Date Embauche</th>
                  <th className="p-2 border">Auto Ecole</th>
                  <th className="p-2 border">Salaire</th>
                </tr>
              </thead>
              <tbody>
                {personnel.length > 0 &&
                  personnel.map((employee) => (
                    <tr key={employee._id} className="border fade text-gray-700 ">
                      <td className="p-2 text-left">{employee.Name}</td>
                      <td>{employee.dateEmbauche}</td>
                      <td>{employee.autoEcole}</td>
                      <td>{employee.salaire}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
};

export default PersonnelList;
