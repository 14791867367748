import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkAuth } from "../../Http/users";
import { getAutos } from "../../Http/autoEcoles";
import { addCandidat } from "../../Http/Candidates";
import Spinner from "../reusable/Spinner/Spinner";
import { CategoryOptions } from "../../constants";

const AddCandidat = () => {
  const navigate = useNavigate();
  const[checked,setChecked]=useState(false)
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [autos, setAutos] = useState([]);
  const [success, setSuccess] = useState("");
  const [dateInput, setDateInput] = useState("text");
  const [isLoading, setIsLoading] = useState(false);
  const [candidat, setCandidat] = useState({
    Fname: "",
    Lname: "",
    Cin: "",
    Adresse: "",
    Categorie: "",
    Price: "",
    autoEcole: "",
    Avance: "",
    Tel: "",
    Referent: "",
    Extension: "",
    dateEntree: "",
    issafen:false
  });

  useEffect(() => {
    getUser();
    loadAutos();
  }, []);
 
  const loadAutos = async () => {
    try {
      const response = await getAutos();

      setAutos(response.data);
    } catch (error) {}
  };

  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);

      navigate("/?error=1");
    }
  };

  const handleChecked =()=>{

    setChecked(!checked)
  }

  const onChange = (e) => {
    setError("");
    setSuccess("");

    setCandidat({
      ...candidat,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      candidat.Fname === "" ||
      candidat.Lname === "" ||
      candidat.Adresse === "" ||
      candidat.Cin === "" ||
      candidat.Categorie === "" ||
      candidat.Price === "" ||
      candidat.autoEcole === ""
    ) {
      return setError("Merci de remplir tous les champs obligatoire");
    }
    if (!parseInt(candidat.Price))
      return setError("Le prix doit etre un chiffre");
    if (candidat.Avance !== "" && !parseInt(candidat.Avance))
      return setError("l'avance doit etre un chiffre");

    candidat.Extension === "Oui"
      ? (candidat.Extension = true)
      : (candidat.Extension = false);
      candidat.issafen = checked
    try {
      setIsLoading(true);
      const response = await addCandidat(candidat);

      if (response.data) {
        setIsLoading(false);
        setSuccess("Nouveau candidat ajouté avec succès");
        setTimeout(() => {
          navigate("/candidats");
        }, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      const errorMesage = error.message || error.reponse.data.message;

      setError(errorMesage);
    }
  };

  return (
    <section className=" w-full bg-gray-200  border rounded-sm shadow-sm  p-2  mx-auto  flex  justify-center items-start">
      <div className="w-11/12 sm:w-2/3 lg:w-3/4 border p-2 bg-white rounded">
        <h1 className="flex justify-center items-center p-2 w-full text-cyan-600  border border-cyan-600 font-bold text-lg tracking-wider">
          Ajouter Un Nouveau Candidat
        </h1>
        <div className="w-full my-2  flex justify-center items-center ">
          {error && (
            <span className="w-full p-2  text-center bg-red-100 text-red-500">
              {error}
              <em className="text-red-500">*</em>{" "}
            </span>
          )}
          {success && (
            <span className="block w-full p-2 bg-green-100 text-green-500 text-center">
              {success}
            </span>
          )}
        </div>

        <form
          onSubmit={onSubmit}
          className="w-full flex flex-col justify-start items-center"
        >
          <div className="w-full my-1 flex flex-col justify-start items-center lg:flex-row md:justify-center  ">
            <div className="w-full flex justify-start items-center lg:w-1/2   mx-1 my-1">
              <input
                type="text"
                placeholder="Prénom du candidat*"
                value={candidat.Fname}
                name="Fname"
                onChange={onChange}
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
              />
            </div>
            <div className="flex w-full lg:w-1/2 justify-start items-center bordermy-1 mx-1">
              <input
                type="text"
                placeholder="Nom du candidat*"
                value={candidat.Lname}
                name="Lname"
                onChange={onChange}
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-2  gap-1">
            <div className="   mx-1 flex justify-start items-center my-1">
              <input
                type="text"
                name="Cin"
                value={candidat.Cin}
                onChange={onChange}
                placeholder="N° de la CIN*"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
              />
            </div>
            <div className="flex justify-start items-center   mx-1 my-1">
              <select
                type="text"
                name="Categorie"
                value={candidat.Categorie}
                onChange={onChange}
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
              >
                <option value="" disabled={true}>
                  Selectionner une catégorie*
                </option>

                {CategoryOptions.map((categorie, index) => {
                  return (
                    <option key={index} value={categorie.value}>
                      {categorie.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex justify-start items-center   mx-1 my-1">
              <select
                name="Extension"
                className="outline-none p-2 w-full m-1  bg-gray-200 rounded "
                value={candidat.Extension}
                onChange={onChange}
              >
                <option value="" disabled={true}>
                  Extension
                </option>
                <option value="Non">Non</option>
                <option value="Oui">Oui</option>
              </select>
            </div>
            <div className="flex justify-start items-center   mx-1 my-1">
              <select
                name="autoEcole"
                value={candidat.autoEcole}
                onChange={onChange}
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
              >
                <option value="" disabled={true}>
                  Selectionner une Auto Ecole*
                </option>

                {autos.length > 0 &&
                  autos.map((auto, index) => {
                    return (
                      <option key={index} value={auto.Name}>
                        {auto.Name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="w-full my-1 flex flex-col justify-start items-center lg:flex-row md:justify-center  ">
            <div className="w-full lg:w-1/2 flex justify-start items-center  mx-1 my-1 ">
              <input
                type="text"
                name="Tel"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Tel}
                onChange={onChange}
                placeholder="N° de téléphone"
              />
            </div>
            <div className="w-full lg:w-1/2 flex justify-start items-center  mx-1 my-1 ">
              <input
                type="text"
                name="Adresse"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Adresse}
                onChange={onChange}
                placeholder="Adresse du candidat*"
              />
            </div>
          </div>
          <div className="w-full   grid grid-cols-1 lg:grid-cols-2 gap-1 ">
            <div className=" w-full   flex justify-start items-center  mx-1 my-1 ">
              <input
                type="number"
                name="Price"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Price}
                onChange={onChange}
                placeholder="Prix*"
              />
            </div>
            <div className="w-full  flex justify-start items-center mx-1 my-1 ">
              <input
                type="number"
                name="Avance"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Avance}
                onChange={onChange}
                placeholder="1ère Avance "
              />
            </div>
          </div>
          <div className="w-full   grid grid-cols-1 lg:grid-cols-2 gap-1 ">
            <div className="w-full  flex justify-start items-center mx-1 my-1 ">
              <input
                type="text"
                name="Referent"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Referent}
                onChange={onChange}
                placeholder="Referent"
              />
            </div>
            
         
            <div className="w-full  flex justify-start items-center  mx-1 my-1 ">
            
              <input
                type={dateInput}
                name="dateEntree"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.dateEntree}
                placeholder="Date entrée"
                onChange={onChange}
                onFocus={() => setDateInput("date")}
              />
            </div>
           
          </div>
          <div className=" w-full  p-2   flex justify-start items-center  ">
            <label htmlFor="issafen" className="font-medium text-gray-600" >
              Issafen:
             </label>
                  <input type="checkbox" value={checked} onChange={handleChecked} className="outline-none  m-1  bg-gray-200 border rounded "/>
            </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <div className="w-full flex justify-evenly items-center p-2">
              <button
                type="submit"
                className="my-4 tracking-wider text-center hover:bg-cyan-500 w-1/3 lg:w-1/5 bg-cyan-600 text-white p-2"
              >
                Enregistrer
              </button>
              <Link
                to="/candidats"
                className="text-center w-1/3 lg:w-1/5 p-2 hover:bg-gray-500 tracking-wider my-4 bg-gray-600 text-white"
              >
                Annuler
              </Link>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default AddCandidat;
