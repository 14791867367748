import { useEffect, useState } from "react";
import Nav from "../components/Nav";
import Header from "../components/reusable/Header";
import { checkAuth } from "../Http/users";
import { getAutos } from "../Http/autoEcoles";
import AddEcheance from "../components/echeances/AddEcheance";
import EcheancesList from "../components/echeances/EcheancesList";
import { getEcheances } from "../Http/Echeance";
import { useNavigate } from "react-router-dom";
import SmallScreenNav from "../components/reusable/SmallScreenNav";

const Echeances = () => {
  const navigate = useNavigate()
  const[showMobMenu,setShowMobMenu]=useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [echeances,setEcheances]=useState([])
    const [autos, setAutos] = useState([]);
  const [user, setUser] = useState(null);
  const defaultAuto = localStorage.getItem("autoEcole") || "Akka";
  const [selectedAuto, setSelectedAuto] = useState(defaultAuto);
  const [reload,setReload]=useState(false)



  useEffect(() => {
    getUser();
    loadAutos();
    loadEchances()

  }, [selectedAuto,user,reload]);
  const loadAutos = async () => {
    try {
      const response = await getAutos();

      setAutos(response.data);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      setIsLoading(false);
    }
  };
  const toggleMobileMenu=()=>{
    setShowMobMenu(!showMobMenu)
  }
  const loadEchances = async ()=>{

    try {
      const response = await getEcheances()
      if(response.data){
  console.log(response.data)
        setEcheances(response.data.data)
      }
      
    } catch (error) {
      
    console.log(error.message)
    }
  }
  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);
      setIsLoading(false);
      navigate("/?error=1");
    }
  };

  const changeSelectedAuto = (payload) => {
    localStorage.setItem("autoEcole", payload);
    setSelectedAuto(payload);
  };
  return (
    <section className=" bg-inherit border rounded-sm shadow-sm w-full  mx-auto  flex flex-col items-center lg:flex-row justify-center lg:items-start">
    <Nav user={user} /><SmallScreenNav user={user} showMobMenu={showMobMenu} toggleMobMen={toggleMobileMenu}/>
      <div className="mx-2 w-full lg:w-4/5 flex flex-col justify-center">
        <Header
          autos={autos}
          selectedAuto={selectedAuto}
          changeSelectedAuto={changeSelectedAuto}
          toggleMobileMenu={toggleMobileMenu}

        />

        <div className="w-full flex flex-col justify-start items-center md:flex-row md:justify-center md:items-start">
        <AddEcheance autos={autos} changeSelectedAuto={changeSelectedAuto} selectedAuto={selectedAuto} reload={reload} setReload={setReload} />
        <EcheancesList echeances={echeances} selectedAuto={selectedAuto} setReload={setReload} reload={reload}/>
        </div>
      </div>
    </section>
  );
};

export default Echeances;
