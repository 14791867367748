import { useState,useEffect } from "react"
import { addCharge } from "../../Http/Charges"
import Vehicules from "../../pages/Vehicules"
import FlashNotification from "../reusable/FlashNotification"
import { getVehicules } from "../../Http/Vehicules"
import { chargesTypes } from "../../constants"

const AddCharge = ({autos,selectedAuto,changeSelectedAuto,setChargeAdded}) => {
 const [notif,setNotif]=useState({
  type:"",
  message:""
 })
 const [vehicules,setVehicules]=useState([])
 const [selectedVehicules,setSelectedVehicules]=useState('')
 const [vehiculeChargeTypeSelected,setVehiculeChargeTypeSelected]=useState(false)
const [nouvelleCharge,setNouvelleCharge] = useState({
        Libelle:"",
        Montant:"",
        dateCharge:"",
        autoEcole: "",
        typeCharge:'',
        vehicule:[]
      })
const [notificationType,setNotificationType] = useState('')
const [message,SetMessage]=useState('')

useEffect(()=>{
 loadVehicules()
},[])
console.log(selectedAuto)

const setNotification=()=>{
  return notif
}
const loadVehicules= async ()=>{
  try {
      const response = await getVehicules()
      if(response.data){
          setVehicules(response.data)
      }
      
  } catch (error) {
      
      console.log(error)
  }
}


const onChange=(e)=>{
       setNotif({
        type:"",
        message:''
       })
        if(e.target.name !== "vehicule"){
          setNouvelleCharge({

            ...nouvelleCharge,[e.target.name]:e.target.value
        })
        }
        console.log(e.target.name,e.target.value)
       if (e.target.name === "typeCharge" && e.target.value === "Véhicules"){
        setVehiculeChargeTypeSelected(true)

       } 
       
       if(e.target.name === "typeCharge" && e.target.value !== "Véhicules"){
        setSelectedVehicules('')
        setVehiculeChargeTypeSelected(false)
       }
      
       
        
          }

          const setChargeVehicule=(e)=>{

            if(e.target.checked){
           selectedVehicules !==e.target.value && setSelectedVehicules(e.target.value)
            }else{
              setSelectedVehicules('')
            }
          }

        

const onSubmit =async(e)=>{
    e.preventDefault()
    if(nouvelleCharge.Libelle === '' || nouvelleCharge.Montant === "") {
        setNotif({
          type:"error",
          message:"Merci de remplir tous les champs"
        })
        return
    }
    if(!parseInt(nouvelleCharge.Montant)){
       setNotif({
        type:"error",
        message:"le champs Montant doit etre un chiffre"
       })
        return
    }
     nouvelleCharge.autoEcole = selectedAuto
     nouvelleCharge.vehicule = selectedVehicules
    try {
        const response = await addCharge(nouvelleCharge)
        if(response.data){
           setNotif({
            type:"success",
            message:"Charge ajoutée avec succès"
           })
           setChargeAdded()
            setNouvelleCharge({
              Libelle:"",
              Montant:"",
              dateCharge:"",
              autoEcole: "",
              typeCharge:''
            })
           setTimeout(() => {
           setNotif({
            type:"",
            message:""
           })
            SetMessage('')
           }, 1000);
        }



        
    } catch (error) {
      const errorMessage = error.message || error.response.data.error
        setNotif({
          type:"error",
          message:errorMessage
        })
    }

}

  return (
    <div className="w-full lg:w-2/5  order-1 md:order-2 flex  flex-col justify-start items-center my-4">
     
        
 <form onSubmit={onSubmit} className="w-11/12 bg-white border flex flex-col justify-start items-center p-2">
  <FlashNotification setNotification={setNotification}/>
 <h1 className="w-full p-2 m-2  border border-cyan-600 text-center  tracking-wide text-cyan-600 font-bold ">Ajouter une nouvelle charge</h1>

  <input 
  type="text" 
  value={nouvelleCharge.Libelle}
  placeholder="Libellé"
  className="w-full p-2 bg-gray-200 outline-none m-2"
  onChange={onChange}
  name="Libelle"
  />
  <input
  type="number"
  value={nouvelleCharge.Montant}
  placeholder="Montant"
  className="w-full p-2 bg-gray-200 outline-none m-2"
  onChange={onChange}
  name="Montant"

  />
  <select
        onChange={(e)=>changeSelectedAuto(e.target.value)}
        value={selectedAuto}
        className="w-full p-2 bg-gray-200 outline-none m-2"
      >
        {autos.length > 0 &&
          autos.map((auto) => {
            return <option key={auto.Name}>{auto.Name}</option>;
          })}
      </select>
      <select  
       onChange={onChange}
       name="typeCharge"
       value={nouvelleCharge.typeCharge}
       className="w-full p-2 bg-gray-200 outline-none m-2"
      >
        <option value=""  disabled={true}>
   Type de charge
        </option>
        {chargesTypes.map(type=>{

          return <option key={type}>
            {type}
          </option>
        })}

      </select>
     {vehiculeChargeTypeSelected ? vehicules?.map(vh=>{
        
        return <div key={vh._id} className="w-full flex justify-start space-x-4">
                

          <label
        className={selectedVehicules === vh.Immat ? "bg-cyan-500 rounded text-white text-center my-1 font-medium w-full md:w-1/3" : "w-full my-1 md:w-1/3 font-medium text-gray-700"}
        htmlFor="vehicule">
        {vh.Immat}
      </label>
      <input
        name="vehicule"
        value={vh.Immat}
        checked={vh.Immat === selectedVehicules}
        type="checkbox"
       className='accent-gray-300'
        onChange={setChargeVehicule}
        />
      </div>
      }): null}
   

  <input 
  type="date"
  value={nouvelleCharge.dateCharge}
  className="w-full p-2 bg-gray-200 outline-none m-2"
  onChange={onChange}
  name="dateCharge"

  />
   
  <input
  type="submit"
  value="Enregistrer"
  className="w-full p-2 bg-cyan-600 text-white cursor-pointer hover:bg-cyan-500 outline-none m-2"
  />
  </form>
</div>
  )
}

export default AddCharge