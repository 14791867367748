import { useState } from "react";
import { addCharge } from "../../Http/Charges";
import { addVehicule } from "../../Http/Vehicules";
import FlashNotification from "../reusable/FlashNotification";
import { CgArrowDownO } from "react-icons/cg";

const AddVehicule = ({ autos, selectedAuto, changeSelectedAuto }) => {
  const [notif, setNotif] = useState({
    type: "",
    message: "",
  });

  const [newVehicule, setNewVehicule] = useState({
    Immat: "",
    dateAssur: "",
    dateVisite: "",
    autoEcole: "",
  });

  const setNotification = () => {
    return notif;
  };

  const onChange = (e) => {
    setNotif({ type: "", message: "" });
    setNewVehicule({
      ...newVehicule,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      newVehicule.Immat === "" ||
      newVehicule.dateAssur === "" ||
      newVehicule.dateVisite === ""
    ) {
      setNotif({ type: "error", message: "Merci de remplir tous les champs" });
      return;
    }

    newVehicule.autoEcole = selectedAuto;

    try {
      const response = await addVehicule(newVehicule);
      if (response.data) {
        setNotif({ type: "success", message: "Véhicule ajouté avec succès" });
        setNewVehicule({
          Immat: "",
          dateAssur: "",
          dateVisite: "",
          autoEcole: "",
        });

        setTimeout(() => {
          setNotif({ type: "", message: "" });
        }, 1000);
      }
    } catch (error) {
      const errorMessage = error.message || error.response.data.error;
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full lg:w-2/5  order-1 md:order-2 flex  flex-col justify-start items-center my-4">
      <FlashNotification setNotification={setNotification} />

      <form
        onSubmit={onSubmit}
        className="w-11/12 bg-white border flex flex-col justify-start items-center p-2"
      >
        <h1 className="w-full p-2 m-2  border border-cyan-600 text-center  tracking-wide text-cyan-600 font-bold ">
          Ajouter un nouveau véhicule
        </h1>
        <label
          htmlFor="Immat"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          N° d'immatriculation:
        </label>
        <input
          type="text"
          value={newVehicule.Immat}
          placeholder="N° d'immatriculation"
          className="w-full p-2 bg-gray-200 outline-none m-2"
          onChange={onChange}
          name="Immat"
        />
        <label
          htmlFor="autoEcole"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Auto ecole:
        </label>
        <select
          onChange={(e) => changeSelectedAuto(e.target.value)}
          value={selectedAuto}
          className="w-full p-2 bg-gray-200 outline-none m-2"
          name="autoEcole"
        >
          {autos.length > 0 &&
            autos.map((auto) => {
              return <option key={auto.Name}>{auto.Name}</option>;
            })}
        </select>
        <label
          htmlFor="dateAssur"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Date fin assurance:
        </label>
        <input
          type="date"
          value={newVehicule.dateAssur}
          className="w-full p-2 bg-gray-200 outline-none m-2"
          onChange={onChange}
          name="dateAssur"
        />
        <label
          htmlFor="dateVisite"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Date fin visite technique:
        </label>
        <input
          type="date"
          value={newVehicule.dateVisite}
          className="w-full p-2 bg-gray-200 outline-none m-2"
          onChange={onChange}
          name="dateVisite"
        />

        <input
          type="submit"
          value="Enregistrer"
          className="w-full p-2 bg-cyan-600 text-white tracking-widest cursor-pointer hover:bg-cyan-500 outline-none m-2"
        />
      </form>
    </div>
  );
};

export default AddVehicule;
