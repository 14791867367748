import { useEffect, useState } from "react";
import Nav from "../components/Nav";
import { checkAuth } from "../Http/users";
import { getAutos } from "../Http/autoEcoles";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/reusable/Spinner/Spinner";
import SmallScreenNav from "../components/reusable/SmallScreenNav";
import Header from "../components/reusable/Header";

export default function Dashboard() {
  const[showMobMenu,setShowMobMenu]=useState(false)

  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
   getUser()
  },[]);
  const toggleMobileMenu=()=>{
    setShowMobMenu(!showMobMenu)
  }
  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);

      navigate("/?error=1");
    }
  };

  const getAutoEcoles = async () => {};

  return !user ? (
    <Spinner />
  ) : (
    <section className="  border rounded-sm shadow-sm w-11/12 p-2  mx-auto  flex justify-center items-start">
    <Nav user={user} /><SmallScreenNav user={user} showMobMenu={showMobMenu} toggleMobMenu={toggleMobileMenu}/>
    <Header
    // autos={autos}
    // selectedAuto={selectedAuto}
    toggleMobileMenu={toggleMobileMenu}
    />
    </section>
  );
}
