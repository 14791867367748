import { useEffect, useState } from "react";
import Nav from "../components/Nav";
import Header from "../components/reusable/Header";
import { checkAuth } from "../Http/users";
import { getAutos } from "../Http/autoEcoles";
import { useNavigate } from "react-router-dom";
import { getCharges } from "../Http/Charges";
import ChargesList from "../components/charges/ChargesList";
import AddCharge from "../components/charges/AddCharge";
import SmallScreenNav from "../components/reusable/SmallScreenNav";

const Charges = () => {
  const navigate = useNavigate();
  const [chargeAdded,setChargeAdded]=useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [autos, setAutos] = useState([]);
  const [success, setSuccess] = useState("");
  const[showMobMenu,setShowMobMenu]=useState(false)

  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [charges, setCharges] = useState([]);
  const defaultAuto = localStorage.getItem("autoEcole") || "Akka";
  const [selectedAuto, setSelectedAuto] = useState(defaultAuto);
  useEffect(() => {
    getUser();
    loadAutos();
    loadCharges();
  }, [selectedAuto,chargeAdded]);

  const changeSelectedAuto = (payload) => {
    localStorage.setItem("autoEcole", payload);
    setSelectedAuto(payload);
  };

  const loadAutos = async () => {
    try {
      const response = await getAutos();

      setAutos(response.data);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const loadCharges = async () => {
    try {
      const response = await getCharges();
      if (response.data) {
        setIsLoading(false);
        setCharges(response.data);
      }
    } catch (error) {
      const errorMessage = error.message || error.response.data.error;
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    try {
      const response = await checkAuth();
      setUser(response.user);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error || error.errorMessage;
      console.log(errorMessage);
      setIsLoading(false);
      navigate("/?error=1");
    }
  };

  const reloadCarges =()=>{
    setChargeAdded(!chargeAdded)
  }

    const toggleMobileMenu=()=>{
      setShowMobMenu(!showMobMenu)
    }
  return (
    <section className=" bg-inherit border rounded-sm shadow-sm w-full  mx-auto  flex flex-col items-center lg:flex-row justify-center lg:items-start">
    <Nav user={user} /><SmallScreenNav user={user} showMobMenu={showMobMenu} toggleMobMenu={toggleMobileMenu}/>
    <div className="mx-2 w-full lg:w-4/5 flex flex-col justify-center">
        <Header
          autos={autos}
          selectedAuto={selectedAuto}
          toggleMobileMenu={toggleMobileMenu}
          />

        <div className="w-full flex flex-col justify-start items-center md:flex-row md:justify-center md:items-start">
          <AddCharge
         
            autos={autos}
            selectedAuto={selectedAuto}
            changeSelectedAuto={changeSelectedAuto}
            setChargeAdded={reloadCarges}
          />
          <ChargesList
            charges={charges}
            selectedAuto={selectedAuto}
            setChargeAdded={reloadCarges}
          />
        </div>
      </div>
    </section>
  );
};

export default Charges;
