import Spinner from "../reusable/Spinner/Spinner";
import moment from "moment";
import { useEffect, useState } from "react";
import {TiDeleteOutline} from 'react-icons/ti'
import { deleteCharge } from "../../Http/Charges";
import FlashNotification from "../reusable/FlashNotification";

const ChargesList = ({ charges, selectedAuto,setChargeAdded}) => {
  const [selectedMonth, setSelectedMonth] = useState(moment().month()+1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [isLoading,setIsLoading]=useState(true)
  const [currentMonthCharges,setCurrentMonthCharges]=useState([])
  const [notif,setNotif]=useState({
    type:null,
    message:""
  })
  let years = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
    2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040,
  ];
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  useEffect(()=>{
    setSelectedMonth(moment().month()+1)
setSelectedYear(moment().year())
getChargesByDate()

  },[selectedAuto,charges.length])

  const onMonthChange =(e)=>{
    setSelectedMonth((e.target.value))
  }

  const getChargesByDate =  (e=null)=>{
  if(e) {
    e.preventDefault()
  }
  
     setCurrentMonthCharges(charges
      .filter((charge) => {
        return (
          charge.autoEcole === selectedAuto &&
          moment(charge.dateCharge).year() === selectedYear &&
          moment(charge.dateCharge).month() === selectedMonth-1
        );
      })
      .sort((a, b) => moment(b.dateCharge).date() - moment(a.dateCharge).date()))
      setIsLoading(false)
  };

  const totalMonthCharges = currentMonthCharges.reduce(
    (a, b) => parseInt(a) + parseInt(b["Montant"]),
    0
  );
 const setNotification =()=>{
  return notif
 }

  const deleteAlert=async(charge)=>{
    if(window.confirm('vous confirmer vouloir supprimer cet enregistrement?'+ charge.Libelle +":"+charge.Montant)){

try {
 const response =  await deleteCharge(charge._id)
 if(response.status === 200){

  setNotif({
    type:"success",
    message:"charge supprimée avec succès"
  })
  setChargeAdded()

  setTimeout(()=>{
    setNotif({
      type:null,
      message:""
    })
  },1000)
 }

  
} catch (error) {

 const errorMessage = error.message || error.response.data.error

 setNotif({
  type:"error",
  message:errorMessage
 })
  
}    
}

  }

  return (
    <div className="w-full lg:w-3/5 order-2 md:order-1">
      {isLoading ?  (
        <div className="w-full flex justify-center items-start">
          <Spinner />
        </div>
      ) :(
        <>
          <div className="fade w-11/12 mx-auto flex justify-center gap-2 items-center bg-white border shadow-sm rounded">
            <h2 className="mx-2 text-gray-700  font-medium ">
              filter par date :
            </h2>
            <form onSubmit={getChargesByDate}>
              <select
                
                className="p-2 bg-gray-200 outline-none m-2"
                value={selectedMonth}
                onChange={(e)=>onMonthChange(e)}
                
              >
                {months.map((month) => {
                  return <option key={month}>{month}</option>;
                })}
              </select>
              <select
                onChange={(e) => setSelectedYear(parseInt(e.target.value) )}
                className=" p-2 bg-gray-200 outline-none m-2"
                value={selectedYear}
              >
                {years.map((year) => {
                  return <option key={year}>
                    {year}
                    </option>;
                })}
              </select>
              <input
                type="submit"
                value="Filtrer"
                className=" p-2 px-4 rounded tracking-wider bg-cyan-600 text-white cursor-pointer hover:bg-cyan-500 outline-none m-2"
              />
            </form>
          </div>
          <div className="p-2 w-11/12 bg-white my-2 border border-gray-200 rouneded">
             
          <FlashNotification setNotification={setNotification}/>

          <table className="w-full my-6  mx-auto text-center">
            <thead className="bg-cyan-600 tracking-wider  text-sm  text-white">
              <th className="p-2 border">Charge</th>
              <th className="p-2 border">Montant</th>
              <th className="p-2 border">Date</th>
            </thead>
            <tbody>
              {currentMonthCharges.map((charge) => {
                return (
                  <tr className="border fade text-gray-700 " key={charge._id}>
                    <td>{charge.Libelle}</td>
                    <td>{charge.Montant}</td>
                    <td className="flex py-2 justify-around items-center">
                    {moment(charge.dateCharge).format("DD-MM-YYYY")} 
                    <span>
                    <TiDeleteOutline onClick={()=>deleteAlert(charge)} className="text-2xl text-red-500 cursor-pointer" />
                    </span></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className=" w-full lg:w-3/4 p-2 my-2 flex justify-start items-center">
            <h2 className="text-gray-600 font-bold text-lg p-2">
              Total des charges du mois:
            </h2>
            <span className="bg-red-600 font-bold text-xl px-3 fade rounded-md text-white tracking-wide">
              {totalMonthCharges} dh
            </span>
          </div>
          </div>
        </>
      ) }
    </div>
  );
};

export default ChargesList;
