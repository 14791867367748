import { Link, useNavigate,useParams } from "react-router-dom"
import { useState,useEffect } from "react"
import { checkAuth } from "../../Http/users"
import { deleteCandidat,getCandidateById } from "../../Http/Candidates"
import Spinner from "../reusable/Spinner/Spinner"
import helpers from "../../helpers"

const DeleteCandidat = () => {
    const {id} = useParams()
    const[isLoading,setIsLoading]=useState(false)
    const navigate = useNavigate()
    const [error,setError]=useState('')
    const [success,setSuccess]=useState('')
    const [candidat,setCandidat]=useState(null)

    const liClass = "flex justify-start items-center my-4"
    const h2Class = "font-bold mx-4"
    useEffect(()=>{
        getUser()
        loadCandidat(id)


    },[id])

    const deleteCandidate = async (id)=>{
        try {
            const response = await deleteCandidat(id)
            if(response.data){
                setSuccess(response.data.message)
                setTimeout(()=>{

                    navigate('/candidats')
                },1000)
            }
            
        } catch (error) {
            const errorMesage = error.message || error.response.data.error
            setError(errorMesage)
        }
    }

    const loadCandidat = async (id)=>{
        setIsLoading(true)
        try {
            const response = await getCandidateById(id)
            if(response.status === 200){
              setCandidat(response.data)
               setIsLoading(false)
            }
            
        } catch (error) {
            const errorMesage = error.message || error.response.data.message
             setError(errorMesage)
             console.log(error)
             setIsLoading(false)
        }
    }


    const getUser = async () => {
        try {
        await checkAuth();
       
        } catch (error) {
          const errorMessage = error.response.data.error || error.errorMessage;
          console.log(errorMessage);
    
          navigate("/?error=1");
        }
      };
  return  (
    <section className="h-screen  border rounded-sm shadow-sm w-11/12 p-2  mx-auto  flex flex-col justify-center items-center">
 <div className="w-full my-4 h-10 flex justify-center items-center ">
        {error && <span className="text-red-600">{error}<em className="text-red-500">*</em> </span>}
    {success && <span className="block w-full p-2 bg-green-600 text-white text-center">{success}</span>}
    </div>
  {candidat ?<div className="w-4/5 flex flex-col justify-start items-center bg-gray-200">
     <ul className=" p-4 flex flex-col justify-start items-start  ">
    <li className= {liClass}>
       <h2 className={h2Class}>
        Nom du candidat:
       </h2>
       <span>{candidat.Fname}{" "} {candidat.Lname} </span>
    </li>
    <li className= {liClass}>
       <h2 className={h2Class}>
        N° de la CIN:
       </h2>
       <span>{candidat.Cin} </span>
    </li>
    <li className= {liClass}>
       <h2 className={h2Class}>
        Adresse:
       </h2>
       <span>{candidat.Adresse} </span>
    </li>
    <li className= {liClass}>
       <h2 className={h2Class}>
        Auto école:
       </h2>
       <span>{candidat.autoEcole} </span>
    </li>
    <li className= {liClass}>
       <h2 className={h2Class}>
        Catégorie:
       </h2>
       <span>{candidat.Categorie} </span>
    </li>
    <li className= {liClass}>
       <h2 className={h2Class}>
       Prix:
       </h2>
       <span>{candidat.Price}{' '}dh </span>
    </li>
    <li className= {liClass}>
       <h2 className={h2Class}>
        Somme des avances:
       </h2>
       <span>{helpers.sumOfPropertyOfArrayOfObjects(candidat.Avances,"Montant")} {' '}dh </span>
    </li>
   </ul>
   <div>
    <h1 className="text-red-600 font-bold">Voulez vous vraiment supprimer cet enregistrement?</h1>
   </div>
   <div className="flex justify-evenly items-center my-4">
    <button className="bg-red-600 hover:bg-red-500 mx-2 px-4 py-1 text-white tracking-wide" onClick={()=>deleteCandidate(id)}>
      Supprimer
    </button>
    <Link to="/candidats" className="mx-2 hover:bg-gray-500 bg-gray-600 px-4 py-1 text-white tracking-wide">
      Annuler
    </Link>
   </div>
   </div> :<Spinner/>}

    </section>
  )
}

export default DeleteCandidat