import axios from "axios";

const CHARGES_URL = "/api/charges"


export const getCharges= async ()=>{

    const response = await axios.get(CHARGES_URL,{ withCredentials: true })

    if(response.status === 200) return response
}

export const addCharge =async (charge)=>{

    const response = await axios.post(CHARGES_URL+'/create',charge,{
        headers:{
            "content-type":"application/json"
        },
        withCredentials: true 
    })
    if(response.status === 201) return response
}


export const deleteCharge =  async(id)=>{

    const response = await axios.delete(CHARGES_URL+'/'+id,{ withCredentials: true })
    if(response.data){

        return response
    }


}