const FlashNotification = ({ setNotification }) => {

  const notification = setNotification()
 return (
  <div className="w-full flex justify-center items-center h-10">
 { notification.type === "success" &&  (
    <div className="w-full my-2  flex justify-center items-center ">
      <span className="block w-full p-2 bg-green-50 text-green-400 text-center">
        {notification.message}
      </span>
    </div>
  )}
{notification.type === "error" && (
    <div className="w-full my-2  flex justify-center items-center ">
      <span className="w-full p-2  text-center bg-red-50 text-red-400">
        {notification.message}
      </span>
    </div>

  )}
  </div>
)}

export default FlashNotification;
