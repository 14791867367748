export const BASE_URL= "https://serverauto2023-production.up.railway.app"

export const CategoryOptions = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "AM",
      value: "AM",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
    {
      label: "D",
      value: "D",
    },
    {
      label: "E",
      value: "E",
    },
  ];

  export  const  chargesTypes = ['Exploitation','Véhicules','Personnel']
