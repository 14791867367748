import axios from "axios";
const AUTOS_URL = "/api/autoecoles";


export const getAutos = async () => {
  const response = await axios.get(AUTOS_URL,{ withCredentials:true});
  if (response.status === 200) {
    return response.data;
  }
};
