import { useState } from "react";
import { addEmployee } from "../../Http/Personnel";
import FlashNotification from "../reusable/FlashNotification";
import { createEcheance } from "../../Http/Echeance";

const AddEmployee= ({ autos, selectedAuto, changeSelectedAuto,reload,setReload }) => {
  const [notif, setNotif] = useState({
    type: null,
    message: "",
  });

  const [newEmployee,setNewEmployee
  ] = useState({
    Name:"",
    dateEmbauche:"",
    autoEcole:selectedAuto,
    salaire:""
  });

  const setNotification = () => {
    return notif;
  };

  const onChange = (e) => {
    setNotif({ type: null, message: "" });
    setNewEmployee({
      ...newEmployee,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      newEmployee.Name === "" ||
      newEmployee.dateEmbauche === "" ||
      newEmployee.salaire === ""
    ) {
      setNotif({ type: "error", message: "Merci de remplir tous les champs" });
      return;
    }

    newEmployee.autoEcole = selectedAuto;

    try {
      const response = await addEmployee(newEmployee);
      await createEcheance({
        Libelle:`salaire - ${newEmployee.Name}`,
        Montant: newEmployee.salaire, 
        typeEcheance:"Personnel",
        jourEcheance: "01",
        Vehicule:null,
        autoEcole:selectedAuto
      })
      if (response.data) {
        setNotif({ type: "success", message: "Véhicule ajouté avec succès" });
        setNewEmployee({
          Name: "",
          dateEmbauche: "",
          autoEcole: selectedAuto,
          salaire: "",
        });
        setReload(!reload)

        setTimeout(() => {
          setNotif({ type: null, message: "" });
        }, 1000);
      }
    } catch (error) {
      const errorMessage = error.response.data.error ||error.message ;
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="w-full lg:w-2/5  order-1 md:order-2 flex  flex-col justify-start items-center my-4">
      <FlashNotification setNotification={setNotification} />

      <form
        onSubmit={onSubmit}
        className="w-11/12 bg-white flex flex-col justify-start items-center p-2"
      >
        <h1 className="w-full p-2 m-2  border border-cyan-600 text-center  tracking-wide text-cyan-600 font-bold ">
          Ajouter un nouvel employé
        </h1>
        <label
          htmlFor="Name"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Nom Complet:
        </label>
        <input
          type="text"
          value={newEmployee.Name}
          placeholder="Nom complet"
          className="w-full p-2 bg-gray-50 outline-none m-2"
          onChange={onChange}
          name="Name"
        />
        <label
          htmlFor="autoEcole"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Auto ecole:
        </label>
        <select
          onChange={(e) => changeSelectedAuto(e.target.value)}
          value={selectedAuto}
          className="w-full p-2 bg-gray-50 outline-none m-2"
          name="autoEcole"
        >
          {autos.length > 0 &&
            autos.map((auto) => {
              return <option key={auto.Name}>{auto.Name}</option>;
            })}
        </select>
        <label
          htmlFor="dateEmbauche"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Date Embauche:
        </label>
        <input
          type="date"
          value={newEmployee.dateEmbauche}
          className="w-full p-2 bg-gray-50 outline-none m-2"
          onChange={onChange}
          name="dateEmbauche"
        />
        <label
          htmlFor="salaire"
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
        >
          Salaire:
        </label>
        <input
          type="number"
          value={newEmployee.salaire}
          className="w-full p-2 bg-gray-50 outline-none m-2"
          onChange={onChange}
          name="salaire"
        />

        <input
          type="submit"
          value="Enregistrer"
          className="w-full p-2 bg-cyan-600 text-white tracking-widest cursor-pointer hover:bg-cyan-500 outline-none m-2"
        />
      </form>
    </div>
  );
};

export default AddEmployee;
