import { useState, useEffect } from "react";
import { CgMathPlus} from "react-icons/cg";
import { chargesTypes } from "../../constants";
import { getVehicules } from "../../Http/Vehicules";
import {createEcheance} from "../../Http/Echeance"
import FlashNotification from "../reusable/FlashNotification";
 
const AddEcheance = ({ autos, selectedAuto,changeSelectedAuto,reload,setReload }) => {
  const [vehicules, setVehicules] = useState([]);

  const [notif, setNotif] = useState({
    type: "",
    message: "",
  });
  const [echeance, setEcheance] = useState({
    Libelle: "",
    Montant: "",
    typeEcheance:"Exploitation",
    jourEcheance: "01",
    Vehicule:null,
    autoEcole:selectedAuto
    
  });
  useEffect(() => {
    loadVehicules();
  }, []);

  const setNotification=()=>{
    return notif
  }
  const loadVehicules = async () => {
    try {
      const response = await getVehicules();
      if (response.data) {
        setVehicules(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let daysOfMonthArray = [];

  for (var i = 1; i <= 31; i++) {
    daysOfMonthArray.push(i);
  }


  const onecheanceChange = (e) => {
    setNotif({});
    setEcheance({
      ...echeance,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async(e) => {
    e.preventDefault()
    if (!echeance.Libelle || !echeance.Montant || !echeance.jourEcheance) {
      setNotif(
        {type:"error",
        message:"Merci de remplir les informations relatives à l'echeance"}
      );
      return
    }
    if(echeance.typeEcheance === "Véhicules"){

      if(echeance.Vehicule === null){

        setNotif(
          {type:"error",
          message:"Si l'echeance est relative à un véhicule, merci de spécifier le n° d'immatriculation "}
        );
        return
      }
    }
    console.log(echeance.typeEcheance)
  
    
    try {
      const response = await createEcheance(echeance)
      if(response.data){
        setNotif({
          type:"success",
          message:"Nouvelle écheance ajoutée avec succès"
        })
        setReload(!reload)
        setTimeout(()=>{
          setNotif({
            type:"",
            message:""
          })
         
        },1000)
      }
      
    } catch (error) {
      console.log()
      setNotif({
        type:"error",
        message: error.response.data.error || "erreur au niveau de vos données!"
      })
      console.log(error.message)
    }
    
  };
 

  return (
    <div className="w-full lg:w-2/5  order-1 md:order-2 flex  flex-col justify-start items-center my-4  bg-white p-2">


      <div className="w-full flex flex-col justify-start items-center  ">
      <FlashNotification setNotification={setNotification}/>

 
      <h2 className="w-full p-2 m-2  border border-cyan-600 text-center flex justify-center ite  tracking-wide text-cyan-600 font-bold">
          <span className="tracking-widest mx-6">Ajouter une echeance</span>
          <CgMathPlus className="text-xl" />
        </h2>
       
      </div>

      <form onSubmit={onSubmit} className="w-full flex flex-col justify-center items-start ">


        <label
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
          htmlFor="Libelle"
        >
          Libelle de l'echeance:
        </label>
        <input
          className="w-full p-2 bg-gray-100 outline-none mt-2"
          onChange={(e) => onecheanceChange(e)}
          name="Libelle"
          value={echeance.Libelle}
          placeholder="Libelle de l'échance"
          type="text"
        />
        <label
          className="flex w-full justify-start font-medium text-gray-600 mt-4"
          htmlFor="Montant"
        >
          Montant de l'echeance:
        </label>
        <input
          value={echeance.Montant}
          className="w-full p-2 bg-gray-100 outline-none mt-2"
          onChange={(e) => onecheanceChange(e)}
          name="Montant"
          placeholder="Montant"
          type="number"
        />

        <label
        className="flex w-full justify-start font-medium text-gray-600 mt-4"
        htmlFor="Montant"
      >
        Auto école:
      </label>
        <select
        onChange={(e)=>changeSelectedAuto(e.target.value)}
        value={selectedAuto}
        className="w-full p-2 bg-gray-200 outline-none mt-2"
      >
        {autos.length > 0 &&
          autos.map((auto) => {
            return <option value={auto.Name} key={auto.Name}>{auto.Name}</option>;
          })}
      </select>
    
        <label
      className="flex w-full justify-start font-medium text-gray-600 mt-4"
      htmlFor="Libelle"
        >
      Nature de l'echeance:
          </label>
          <select  
                   onChange={onecheanceChange}
                    name="typeEcheance"
                   value={echeance.typeEcheance}
                  className="w-full p-2 bg-gray-100 outline-none mt-2"
                    >
                   <option   disabled={true}>
                            Type d'Echeance
                    </option>
       {chargesTypes.map(type=>{

         return <option value={type} key={type}>
           {type}
         </option>
       })}

     </select>
     {echeance.typeEcheance ===  "Véhicules" && (
      <>
       <label
      className="flex w-full justify-start font-medium text-gray-600 mt-4"
      htmlFor="Libelle"
    >
      Véhicule concerné:
    </label>
      <select
      onChange={onecheanceChange}
      name="Vehicule"
      value={echeance.Vehicule}
      className="w-full p-2 bg-gray-100 outline-none mt-2"
      >
      <option value=""  disabled={true}>
      Choisir Véhicule
           </option>
           {vehicules?.map((vh)=>{
            return <option key={vh._id}>
            {vh.Immat}
            </option>
           })}
      </select>
      </>)}
      <label
        htmlFor="jourEcheance"
        className="flex w-full justify-start font-medium text-gray-600 mt-4"
      >
        Jour de l'echeance:
      </label>
      <select
        value={echeance.jourEcheance}
        onChange={(e) => onecheanceChange(e)}
        name="jourEcheance"
        className="w-full p-2 bg-gray-100 outline-none mt-2"
      >
        {daysOfMonthArray.map((day) => (
          <option value={day < 10 ? `0${day}` : day} key={day}>
            {day < 10 ? `0${day}` : day}
          </option>
        ))}
      </select>
      <input
      
      type="submit"
      value="Enregistrer"
      className="w-full p-2 bg-cyan-600 text-white cursor-pointer hover:bg-cyan-500 outline-none m-2"
      />
      </form>
    </div>
  );
};
export default AddEcheance;
