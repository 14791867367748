import axios from "axios";

const PERSONNEL_URL ="/api/personnel"



export const getPersonnel =async ()=>{

    const response= await axios.get(PERSONNEL_URL,{ withCredentials: true })
    
    if(response.status === 200){
        return response
    }
}

export const addEmployee =async (employee)=>{

    const response = await axios.post(PERSONNEL_URL+"/create",employee,{
        headers:{
            "Content-Type":"application/json"
        },
        withCredentials: true 
    })
    if(response.status === 201){
        return response
    }
}