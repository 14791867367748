import { useEffect, useState } from "react"
import { Link, useNavigate,useParams } from "react-router-dom"
import { checkAuth } from "../../Http/users"
import { getAutos } from "../../Http/autoEcoles"
import {  getCandidateById,updateCandidat } from "../../Http/Candidates"
import Spinner from "../reusable/Spinner/Spinner"
import moment from 'moment'


const EditCandidat = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [id,setId]=useState(params.id)
    const [error,setError] = useState('')
    const [user,setUser]=useState(null)
    const [autos,setAutos] = useState([])
    const [success,setSuccess]= useState('')
    const [isLoading,setIsLoading]=useState(false)
    const [checked,setChecked]=useState(false)

    const [candidat,setCandidat]=useState({})
    
  
    useEffect(()=>{
        getUser();
        loadAutos()
        loadCandidat(id)
     

    },[])
    

    const CategoryOptions=[{
        label:"A",
        value:"A"
    
       },
       {
        label:"AM",
        value:"AM"
    
       },
       {
        label:"B",
        value:"B"
       },
       {
        label:"C",
        value:"C"
       },
       {
        label:"D",
        value:"D"
       },
       {
        label:"E",
        value:"E"
       }
    ]
    const onCheckedChange=(e)=>{
      setCandidat({
        ...candidat,issafen:e.target.checked
      })
    }
  const loadAutos = async () => {
    try {
      const response = await getAutos();
      setAutos(response.data);
    } catch (error) {}
  };
   
    const getUser = async () => {
        try {
          const response = await checkAuth();
          setUser(response.user);
        } catch (error) {
          const errorMessage = error.response.data.error || error.errorMessage;
          console.log(errorMessage);
    
          navigate("/?error=1");
        }
      };
  
  

    const onChange=(e)=>{

        setError('')
        setSuccess('')
        setCandidat({
            ...candidat,[e.target.name]:e.target.value
        })
    }

    const onSubmit=async (e)=>{
        
        e.preventDefault()
        setIsLoading(true)
        if(candidat.Fname === "" || candidat.Lname === "" || candidat.Adresse === ""
        || candidat.Cin === "" || candidat.Categorie === ""
        || candidat.Price === "" || candidat.autoEcole === ""  ){
            setIsLoading(false)
            return setError('Merci de remplir tous les champs obligatoire')
        }
        if(!parseInt(candidat.Price) ) {
            setIsLoading(false)
            return  setError('Le prix doit etre un chiffre')
        }
        candidat.Extension = candidat.Extension === "Oui" ? true : false
        
        try {
      
            const response = await updateCandidat(candidat)
            
           if(response.data){
              setIsLoading(false)
                setSuccess(response.data.message)
                setTimeout(()=>{
                   
                    navigate('/candidats')
                },1000)
        
        }
            
        } catch (error) {
            setIsLoading(false)
            const errorMesage = error.message || error.reponse.data.message

            setError(errorMesage)
            
        }
    }
   
    
    const loadCandidat = async (id)=>{
        setIsLoading(true)
        try {
            const response = await getCandidateById(id)
            if(response.status === 200){
                const editable = response.data
               editable.dateEntree = moment(editable.dateEntree).format('DD-MM-yyyy')
               setCandidat(editable)
               setIsLoading(false)
            }
            
        } catch (error) {
            const errorMesage = error.message || error.response.data.message
             setError(errorMesage)
             console.log(error)
             setIsLoading(false)
        }
    }

    

    
    return  (
        <section className=" w-full  relative  border rounded-sm shadow-sm  p-2  mx-auto  flex  justify-center items-start">
     
       <div className="w-11/12 bg-white sm:w-2/3 lg:w-3/4 border p-4">
       <h1 className="flex justify-center items-center p-2 w-full text-cyan-600  border border-cyan-600 font-bold text-lg tracking-wider">
          Modifier les données de {candidat.Fname} {" "} {candidat.Lname}
        </h1>       
        <div className="w-full my-1  flex justify-center items-center ">
          {error && (
            <span className="w-full p-2  text-center bg-red-100 text-red-500">
              {error}
              <em className="text-red-500">*</em>{" "}
            </span>
          )}
          {success && (
            <span className="block w-full p-2 bg-green-100 text-green-500 text-center">
              {success}
            </span>
          )}
        </div>
    
       
      <form onSubmit={onSubmit} className="w-full flex flex-col justify-start items-center">
        <div className="w-full my-2 flex flex-col justify-start items-center lg:flex-row md:justify-center  ">
           <div className="w-full flex flex-col justify-center items-start lg:w-1/2  mx-1 my-1">
             <label className=" w-1/4  text-gray-500">Prénom<em className="text-red-500">*</em>:</label>
            <input
             type="text"
             placeholder="Prénom du candidat"
             value={candidat.Fname}
             name="Fname"
             onChange={onChange}
             className="outline-none p-2 w-full m-1  bg-gray-200 border rounded-md"
             />
           </div>
           <div className="flex flex-col w-full lg:w-1/2 justify-center items-start  my-1 mx-1">
           <label className=" w-1/4  text-gray-500">Nom<em className="text-red-500">*</em>:</label>
            <input
             type="text"
             placeholder="Nom du candidat"
             value={candidat.Lname}
             name="Lname"
             onChange={onChange}
             className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
             />
    
           </div>
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2  gap-1">
           <div className="  mx-1 flex flex-col justify-center items-start my-1">
           <label className=" w-1/4  text-gray-500">CIN<em className="text-red-500">*</em>:</label>
                <input
                type="text"
                name="Cin"
                value={candidat.Cin}
                onChange={onChange}
                placeholder="N° de la CIN"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
    
                />
            </div>
            <div className="flex flex-col justify-center items-start  mx-1 my-1">
            <label className=" w-1/4  text-gray-500">Catégorie<em className="text-red-500">*</em>:</label>
                <select
                type="text"
                name="Categorie"
                value={candidat.Categorie}
                onChange={onChange}
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
    
                
                >
                    {CategoryOptions.map((categorie,index)=>{
                       return <option key={index} value={categorie.value}>
                            {categorie.label}
                        </option>
                    })}
                    </select>
            </div>
            <div className="flex flex-col justify-center items-start  mx-1 my-1">
            <label className=" w-1/4  text-gray-500">Extension<em className="text-red-500">*</em>:</label>
                <select  
                name="Extension"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                 value={candidat.Extension === true ? "Oui":"Non"}
                  onChange={onChange}>
                    <option value="Non" >
                        Non
                    </option>
                    <option value="Oui" >
                        Oui
                    </option>
                </select>
            </div>
            <div className="flex flex-col justify-center items-start  mx-1 my-1">
            <label className=" w-1/4  text-gray-500">Auto Ecole<em className="text-red-500">*</em>:</label>
               <select 
               name="autoEcole"
              value={candidat.autoEcole}
             onChange={onChange}
             className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
             >
    
    
                 {autos.length>0 && autos.map((auto,index)=>{
                return <option key={index} value={auto.Name}>
                          {auto.Name}
                </option>
                 })}
                 </select>
           
            
                  </div>
           
    
        </div>
        <div className="w-full my-1 flex flex-col justify-start items-center lg:flex-row md:justify-center  ">
          <div className="w-full lg:w-1/2 flex flex-col justify-center items-start  mx-1 my-1 ">
          <label className=" w-1/4  text-gray-500">N° de Tél:</label>
                <input
                type="text"
                name="Tel"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Tel?? ""}
                onChange={onChange}
                placeholder="N° de téléphone"
                />
           </div>
           <div className="w-full lg:w-1/2 flex flex-col justify-center items-start  mx-1 my-1 ">
           <label className=" w-1/4  text-gray-500">Adresse<em className="text-red-500">*</em>:</label>
                <input
                type="text"
                name="Adresse"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Adresse}
                onChange={onChange}
                placeholder="Adresse du candidat"
                />
            </div>
        
        </div>
        <div className="w-full   grid grid-cols-1 lg:grid-cols-2 gap-1 ">
            <div className=" w-full   flex flex-col justify-center items-start  mx-1 my-1 ">
            <label className=" w-1/4  text-gray-500">Prix<em className="text-red-500">*</em>:</label>
            <input
            type="number"
                name="Price"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Price}
                onChange={onChange}
                placeholder="Prix"
                />
                
            </div>
            <div className="w-full  flex flex-col justify-center items-start  mx-1 my-1 ">
            <label className=" w-1/4  text-gray-500">Referent:</label>
              <input
            type="text"
                name="Referent"
                className="outline-none p-2 w-full m-1  bg-gray-200 border rounded "
                value={candidat.Referent?? ""}
                onChange={onChange}
                placeholder="Referent"
                />
                
             </div>
             <div className=" w-full  p-2   flex justify-start items-center  ">
            <label htmlFor="issafen" className="font-medium text-gray-600" >
              Issafen:
             </label>
                  <input type="checkbox" checked={candidat.issafen}   value={candidat.issafen} onChange={onCheckedChange} className="outline-none  m-1  bg-gray-200 border rounded "/>
            </div>
           
         </div>
       
     
             
       
       
      {isLoading ? <Spinner/> : <div className="w-full flex justify-evenly items-center p-2">
        <button type="submit"                 className="my-4 tracking-wider text-center hover:bg-cyan-500 w-1/3 lg:w-1/5 bg-cyan-600 text-white p-2"
>
            
            Enregistrer
        </button>
        <Link to='/candidats'  className="text-center w-1/3 lg:w-1/5 p-2 hover:bg-gray-500 tracking-wider my-4 bg-gray-600 text-white">
            Annuler
        </Link>
        </div>}  
      </form>
       </div>
      </section>
      )
    }


export default EditCandidat